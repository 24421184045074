<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-messagetemplate-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success mt-2 mb-0">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="row row-cols-1">
              <div class="col col-md-6">
                <label for="Name" class="form-label required"
                  >{{
                    $t(
                      "MessageTemplates.Name",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Name"
                  v-model="messageTemplateData.name"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col col-md-6">
                <label for="TemplateType" class="form-label required">{{
                  $t(
                    "MessageTemplates.TemplateType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="selectedTemplateType"
                  :allowEmpty="false"
                  :data="templateTypeData"
                  @onChange="onChangeForTemplateType"
                />
              </div>
            </div>
            <div class="row row-cols-1 mt-3">
              <div class="col col-md-12">
                <label for="Description" class="form-label">{{
                  $t(
                    "MessageTemplates.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <textarea
                  name="Description"
                  class="form-control"
                  id="Description"
                  rows="2"
                  v-model="messageTemplateData.description"
                  :spellcheck="this.$isTextSpellCheck"
                ></textarea>
              </div>
            </div>
            <div class="cols-12 mt-3">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "MessageTemplates.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="messageTemplateData.isActive"
                />
              </div>
            </div>
            <div class="cols-12 mt-2">
              <label for="TemplateDesign" class="form-label">{{
                $t(
                  "MessageTemplates.TemplateDesign",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <CKEditor
                ref="ckEditor"
                id="TemplateDesign"
                type="MessageTemplate"
                :value="messageTemplateData.templateDesign"
              />
            </div>
            <ActionButtons
              classes="mt-3"
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CKEditor from "@/components/ckeditor/Editor.vue";
import $ from "jquery";
export default {
  name: "DesignMessageTemplateNew",
  components: { CKEditor },
  data() {
    return {
      messageTemplateData: {
        templateDesign: "",
        templateType: 1,
        templateTypeText: "Email",
        isActive: true,
        description: "",
      },
      templateTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SMS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.SystemNotification",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedTemplateType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      isSuccess: false,
    };
  },
  methods: {
    onChangeForTemplateType: function (selected) {
      this.messageTemplateData.templateType = selected.key;
      if (selected.key == 1) {
        this.messageTemplateData.templateTypeText = "Email";
      } else if (selected.key == 2) {
        this.messageTemplateData.templateTypeText = "SMS";
      } else if (selected.key == 3) {
        this.messageTemplateData.templateTypeText = "SystemNotification";
      }
    },
    onClear() {
      this.messageTemplateData = {
        templateDesign: "",
        templateType: 1,
        templateTypeText: "Email",
        isActive: true,
        description: "",
      };
      $(".form-messagetemplate-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-messagetemplate-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.messageTemplateData.templateDesign =
        this.$refs.ckEditor.editor.getData();

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-MessageTemplateNew", this.messageTemplateData)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/ProjectDesign/MessageTemplate/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
  mounted() {},
};
</script>
